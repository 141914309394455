
import { loadFull } from "tsparticles";

export default {
  setup() {
    return {
      particlesInit: async (engine) => (await loadFull(engine)),
      particlesLoaded: async (container) => { console.log("Particles container loaded", container) },
    }
  } 
}
