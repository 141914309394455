import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Particles = _resolveComponent("Particles")!

  return (_openBlock(), _createBlock(_component_Particles, {
    id: "tsparticles",
    particlesInit: $setup.particlesInit,
    particlesLoaded: $setup.particlesLoaded,
    options: {
        background: null,
        fpsLimit: 60,
        particles: {
          color: {
            value: '#ffffff'
          },
          move: {
            direction: 'none',
            enable: true,
            outMode: 'bounce',
            random: false,
            speed: 1,
            straight: false
          },
          number: {
            density: {
              enable: true,
              area: 800
            },
            value: 10
          },
          opacity: {
            value: 0.3
          },
          shape: {
            type: 'circle'
          },
          size: {
            random: true,
            value: 5
          }
        },
        detectRetina: true
    }
  }, null, 8, ["particlesInit", "particlesLoaded"]))
}